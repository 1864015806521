import React from "react";
import {
  TextField,
  NumberField,
  DateField,
  BooleanField,
  TextInput,
  SelectInput,
} from "react-admin";
import { MyList } from "../../_myBase/components/myList";
import useFieldPreferences from "../../hooks/useFieldPreferences";

const discountTypeChoices = [
  { id: "percentage", name: "Percentage" },
  { id: "fixed", name: "Fixed" },
];

const filters = [
  <TextInput label="Code" source="code" alwaysOn key={1} />,
  <SelectInput
    label="Discount Type"
    source="discount_type"
    choices={discountTypeChoices}
    alwaysOn
    key={2}
  />,
];

const allFields = [
  { name: "id", component: TextField, props: { label: "ID", sortable: false } },
  {
    name: "code",
    component: TextField,
    props: { label: "Code", sortable: true },
  },
  {
    name: "discount_type",
    component: TextField,
    props: { label: "Discount Type" },
  },
  {
    name: "discount_value",
    component: NumberField,
    props: { label: "Discount Value" },
  },
  {
    name: "min_purchase_amount",
    component: NumberField,
    props: { label: "Min Purchase Amount" },
  },
  {
    name: "max_tries_per_user",
    component: NumberField,
    props: { label: "Max Tries Per User" },
  },
  {
    name: "usage_limit",
    component: NumberField,
    props: { label: "Usage Limit" },
  },
  {
    name: "times_used",
    component: NumberField,
    props: { label: "Times Used" },
  },
  { name: "valid_from", component: DateField, props: { label: "Valid From" } },
  {
    name: "valid_until",
    component: DateField,
    props: { label: "Valid Until" },
  },
  { name: "is_active", component: BooleanField, props: { label: "Active" } },
  { name: "created_at", component: DateField, props: { label: "Created At" } },
];

export default function CouponsList(props) {
  const { visibleFields, loading, updatePreference } = useFieldPreferences(
    "coupons",
    allFields.map((field) => field.name)
  );

  if (loading) return <div>Loading...</div>;

  const filteredFields = allFields.filter((field) =>
    visibleFields.includes(field.name)
  );

  return (
    <MyList
      resource="coupons"
      {...props}
      filters={filters}
      fields={allFields.map((field) => field.name)}
      onPreferencesUpdate={updatePreference}
    >
      {filteredFields.map(
        ({ name, component: Component, props: fieldProps }) => (
          <Component key={name} source={name} {...fieldProps} />
        )
      )}
    </MyList>
  );
}

import React from "react";
import { TextField, BooleanField, TabbedShowLayout } from "react-admin";
import {
  LabeledColumn,
  TitledShow,
} from "../../_myBase/components/titledRecord";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import PackagesList from "../packages/PackagesList";
import FeedBacksList from "../feedbacks/FeedBacksList";

export default function UserShow() {
  const id = parseInt(useParams()["id"]);

  return (
    <TitledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Summary">
          <Stack spacing={4} direction="row">
            <LabeledColumn>
              <TextField source="id" />
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="email" />
              <BooleanField source="emailValidated" />
              <BooleanField source="isActive" />
              <BooleanField source="isSuperuser" />
              <TextField source="phoneNumber" />
              <BooleanField source="phoneValidated" />
              <BooleanField source="isHost" />
            </LabeledColumn>
          </Stack>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Sent Packages" path="packages-sent">
          <PackagesList filter={{ senderId: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Receipt Packages" path="packages-receipt">
          <PackagesList filter={{ recipientId: id }} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Feedbacks" path="feedbacks">
          <FeedBacksList filter={{ userId: id }} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </TitledShow>
  );
}

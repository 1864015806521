import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateTimeInput,
  BooleanInput,
  SelectInput,
} from "react-admin";

const discountTypeChoices = [
  { id: "percentage", name: "Percentage" },
  { id: "fixed", name: "Fixed" },
];

export default function CouponsEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput disabled source="id" label="ID" />
        <TextInput source="code" label="Code" />
        <SelectInput
          source="discount_type"
          label="Discount Type"
          choices={discountTypeChoices}
        />
        <NumberInput source="discount_value" label="Discount Value" />
        <NumberInput source="min_purchase_amount" label="Min Purchase Amount" />
        <NumberInput source="max_tries_per_user" label="Max Tries Per User" />
        <NumberInput source="usage_limit" label="Usage Limit" />
        <DateTimeInput source="valid_from" label="Valid From" />
        <DateTimeInput source="valid_until" label="Valid Until" />
        <BooleanInput source="is_active" label="Active" />
      </SimpleForm>
    </Edit>
  );
}

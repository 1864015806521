import React from "react";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { dataProvider } from "./components/DataProvider";
import MyLayout from "./_myBase/theme/index";

import UserIcon from "@mui/icons-material/People";
import Box from "@mui/icons-material/Book";
import LocalAtm from "@mui/icons-material/LocalAtm";

import AuthProvider from "./components/AuthProvider";
import UsersList from "./components/users/UsersList";
import UserCreate from "./components/users/UserCreate";
import UserEdit from "./components/users/UserEdit";
import UserShow from "./components/users/UserShow";
import LocationList from "./components/locations/LocationList";
import LocationShow from "./components/locations/LocationShow";
import PackagesList from "./components/packages/PackagesList";
import PackageCreate from "./components/packages/PackageCreate";
import PackageEdit from "./components/packages/PackageEdit";
import PackageShow from "./components/packages/PackageShow";
import PackageStatusesList from "./components/package_statuses/PackageStatusesList";
import PackageStatusesCreate from "./components/package_statuses/PackageStatusesCreate";
import PricingPlanList from "./components/pricing_plans/PricingPlanList";
import PricingPlanCreate from "./components/pricing_plans/PricingPlanCreate";
import PricingPlanShow from "./components/pricing_plans/PricingPlanShow";
import PricingPlanEdit from "./components/pricing_plans/PriingPlanEdit";
import { myCustomRoutes } from "./_myBase/components/customRoutes";
import PackageStatusesShow from "./components/package_statuses/PackageStatusesShow";
import HostTowersList from "./components/host_towers/HostTowersList";
import HostTowersCreate from "./components/host_towers/HostTowersCreate";
import HostTowersEdit from "./components/host_towers/HostTowersEdit";
import HostTowersShow from "./components/host_towers/HostTowersShow";
import { Route } from "react-router-dom";
import ForgotPassword from "./components/forgot_password/ForgotPassword";
import ResetPassword from "./components/reset_password/ResetPassword";
import CustomLogin from "./components/custom_login.js/CustomLogin";
import HostTowerStatusLogsList from "./components/host_tower_status_logs/HostTowerStatusLogsList";
import CouponsList from "./components/coupons/CouponsList";
import CouponsCreate from "./components/coupons/CouponsCreate";
import CouponsEdit from "./components/coupons/CouponsEdit";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      layout={MyLayout}
      loginPage={CustomLogin}
    >
      <CustomRoutes noLayout>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </CustomRoutes>
      <Resource
        name="users"
        list={UsersList}
        create={UserCreate}
        edit={UserEdit}
        show={UserShow}
        icon={UserIcon}
      />
      <Resource
        name="packages"
        list={PackagesList}
        create={PackageCreate}
        edit={PackageEdit}
        show={PackageShow}
        icon={Box}
      />
      <Resource
        name="package-statuses"
        list={PackageStatusesList}
        create={PackageStatusesCreate}
        show={PackageStatusesShow}
      />
      <Resource
        name="pricing-plans"
        list={PricingPlanList}
        edit={PricingPlanEdit}
        show={PricingPlanShow}
        create={PricingPlanCreate}
        icon={LocalAtm}
      />
      <CustomRoutes>{myCustomRoutes()}</CustomRoutes>
      <Resource
        name="host-towers"
        list={HostTowersList}
        create={HostTowersCreate}
        edit={HostTowersEdit}
        show={HostTowersShow}
      />
      <Resource name="locations" list={LocationList} show={LocationShow} />
      <Resource name="host-tower-status-logs" list={HostTowerStatusLogsList} />
      <Resource
        name="coupons"
        list={CouponsList}
        create={CouponsCreate}
        edit={CouponsEdit}
      />
    </Admin>
  );
}

export default App;
